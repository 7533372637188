import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  userId!: string;
  accountTypeId!: number;
  private subs: Subscription = new Subscription();
  accountTypeIdNew!: number;
  userAccountInformationChanged = 0;
  disableRadioButtons : boolean= false;
  constructor(
    private route: ActivatedRoute
  ) { } 

  ngOnInit(): void {
    this.subs = this.route.params.subscribe(params => {
      this.userId = params.uid;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }  

  accountTypeIdExist(event: number): void {
    this.accountTypeId = event;
  }

  onChangeAccountType(event: number): void {
    if (event) {
      this.accountTypeIdNew = event;
    }
  }

  onUserAccountInformationChanged(): void {
     this.userAccountInformationChanged ++;
  }
  ondiableradionbtns(event:boolean){
    this.disableRadioButtons = event;
   }
}
