import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DualAccountTypesId } from '@core/constants/pacIm-account-type-id';
import { BusinessType, UserTenants } from '@core/model';
import { TenantsService } from '@core/services';
import { SelectItem } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { getAccountTypeOptions } from '@utils/business-type-utilities';
import { WccAccountTypes } from '@core/enums/wcc-account-type.enum';
import { ChappAccountTypes } from '@core/enums/chapp-account-type.enum';
import { TenantNames } from '@mynexus/mynexus-ui-lib';

interface HealthPlanSelectItemValue {
  tenantId: string,
  healthPlanName: string
}

@Component({
  selector: 'app-account-information-widget',
  templateUrl: './account-information-widget.component.html',
  styleUrls: ['./account-information-widget.component.scss']
})
export class AccountInformationWidgetComponent implements OnInit, OnDestroy, OnChanges {
  private userIdValue = '';
  private accountTypeIdValue!: number;
  private subs: Subscription = new Subscription();
  businessType?: BusinessType;
  @Input() disableRadioButtons : boolean = false;

  @Input() set userId(value: string) {
    this.userIdValue = value;
  }

  get userId(): string {
    return this.userIdValue;
  }
  @Input() set accountTypeId(value: number) {
    this.accountTypeIdValue = value;
  }

  get accountTypeId(): number {
    return this.accountTypeIdValue;
  }
  @Output() accountTypeIdNew = new EventEmitter<number>();
  @Output() userAccountInformationChanged = new EventEmitter();
  isLoading = false;
  healthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];
  applicableHealthPlans: SelectItem<HealthPlanSelectItemValue>[] = [];

  healthPlan = '';
  businessTypes: { [key: string]: BusinessType} = {};
  applicableAccountTypes: SelectItem[] = [];
  accountTypes: SelectItem[] = [];
  showAssignProvidersWidget = true;
  hasSelectedProviders = false;

  providersListChanges$: Subject<void> = new Subject<void>();
  accountType$: Subject<number> = new Subject<number>();

  constructor(private tenantsService: TenantsService) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.subs = this.tenantsService.getTenants()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: UserTenants) => {
        this.healthPlans = [];
        this.accountTypes = [];

        response.businessTypes?.forEach(bt =>
          this.businessTypes[bt.businessTypeID] = bt
          );

        this.businessType = this.businessTypes[this.accountTypeId];
          
        if (response && response.tenants && response.businessTypes) {
          this.healthPlans = response.tenants.map(el => ({ label: el.name, value: { healthPlanName:el.name, tenantId: el.tenantId} }));
          this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsBusinessType(hp.label, this.businessType));
          this.accountTypes = getAccountTypeOptions(response.businessTypes);

          this.applicableAccountTypes =
            this.accountTypes.filter(at => !this.healthPlan || this.healthPlanSupportsBusinessType(this.healthPlan, at.value));
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.accountTypeId && !changes.accountTypeId.isFirstChange()) {
      this.isAssignProvidersWidgetShown(this.accountTypeId);
      this.businessType = this.businessTypes[changes.accountTypeId.currentValue];
      this.applicableHealthPlans = this.healthPlans.filter(healthPlan => this.healthPlanSupportsBusinessType(healthPlan.value.healthPlanName, this.businessType));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onChangeHealthPlan($event: HealthPlanSelectItemValue): void {
      this.applicableAccountTypes = this.accountTypes.filter(at => this.healthPlanSupportsBusinessType($event.healthPlanName, this.businessTypes[at.value]));
    }

  onChangeAccountType(event: number): void {
    if (event) {
      this.applicableHealthPlans = this.healthPlans.filter(hp => this.healthPlanSupportsBusinessType(hp.value.healthPlanName, this.businessTypes[event]));
      this.accountTypeIdNew.emit(event);
      this.isAssignProvidersWidgetShown(event);
      this.accountType$.next(event);
    }
  }

  onSelectProvider(event: boolean): void {
    this.hasSelectedProviders = event;
  }

  onUserProvidersChanged(): void {
    this.providersListChanges$.next();
    this.userAccountInformationChanged.emit();
  }

  isAssignProvidersWidgetShown(value: number): void {
    this.showAssignProvidersWidget = !DualAccountTypesId.includes(value);
  }

  private healthPlanSupportsBusinessType(tenantName?: string, businessType?: BusinessType): boolean {

    if (this.checkBusinessTypeVisibility(businessType)) return false;

    if (this.checkBusinessTypeRestriction(tenantName, businessType)) return false;
    return true;

  }

  private checkBusinessTypeVisibility(businessType?: BusinessType): boolean {
    const hiddenPlansList = [WccAccountTypes.WccProviderAdmin, WccAccountTypes.WccClinician];

    const isHiddenPlan = (type?: string) => {
      return hiddenPlansList.findIndex(t => type?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0 ) !== -1;
    };
    return isHiddenPlan(businessType?.businessType);
    
  }

  private checkBusinessTypeRestriction(tenantName?: string, businessType?: BusinessType): boolean {
    const restrictedSupportBusinessPlans = [
      ChappAccountTypes.ChappProvider,
      ChappAccountTypes.SpecialtyProgramClinician,
      ChappAccountTypes.SpecialtyProgramProviderAdmin,
    ];

    const excludedTenants = [TenantNames.Anthem, TenantNames.Regence, TenantNames.Wellpoint];

    const isRestrictedSupportType = (type?: string) => {
      return restrictedSupportBusinessPlans.findIndex(t => type?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0) !== -1;
    };

    const isExcludedTenant = (name?: string) => {
      return excludedTenants.findIndex(t => name?.localeCompare(t, undefined, { sensitivity: 'base' }) === 0) !== -1;
    };
    return isRestrictedSupportType(businessType?.businessType) && !isExcludedTenant(tenantName)
  }

}