import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DualAccountTypesId,
  PacImAccountTypesId,
  PacImAccountTypesValue,
  StarsAccountTypesValue
} from '@core/constants';
import { DmeposAccountTypesId } from '@core/constants/dmepos-account-type-id';
import { DmeposAccountTypesValue } from '@core/constants/dmepos-account-types-value';
import { PACIM_ACCOUNT_TYPE_ENUM } from '@core/enums/pacim-account-type.enum';
import { STARS_ACCOUNT_TYPE_ENUM } from '@core/enums/stars-account-type.enum';
import { TenantsService } from '@core/services';
import { MessageService, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-account-switch',
  templateUrl: './account-switch.component.html',
  styleUrls: ['./account-switch.component.scss']
})
export class AccountSwitchComponent {
  private readonly anthemId = '548bead1-bdab-e811-bce7-0003ff21d46b';
  private readonly regenceId = '81c8042e-6c6d-4aca-9d29-59fb3ef734f5';
  private readonly WellpointId = "bb390147-666b-4004-98b9-27d7380dc19d"
  private readonly accountTypesOrder = [10, 9, 6, 4, 3, 2, 1, 12, 11];
  private accountIdValue!: number;
  private accountTypesValue!: SelectItem[];
  private readonly healthPlanCaseManagerAccountTypeId = 3;

  @Input() userId = '';

  @Input() hasSelectedProviders = false;
  @Input() disableRadioButtons: boolean = false;

  @Input() set tenantId(value: string) {
    this.tenant = value;

    switch (value) {
      case this.anthemId:
      case '':
        this.availableAccountTypes = this.accountTypesValue;
        break;
      case this.regenceId:
        this.availableAccountTypes = this.accountTypesValue.filter(
          (e: SelectItem) =>
            [...PacImAccountTypesValue, 'Home Health Agency', 'Physician', 'Health Plan Case Manager',
              'Hospital Case Manager/Discharge Planner'].includes(e.label as PACIM_ACCOUNT_TYPE_ENUM | string)
        )
        break;
      case this.WellpointId:
          this.availableAccountTypes = this.accountTypesValue.filter((e: SelectItem) =>
            [...PacImAccountTypesValue,
              'Physician',
              'Health Plan Case Manager',
            ].includes(e.label as PACIM_ACCOUNT_TYPE_ENUM | string)
        )
        break;
      default:
        this.availableAccountTypes = this.accountTypesValue.filter(
          (e: SelectItem) =>
            ![...PacImAccountTypesValue, ...StarsAccountTypesValue, ...DmeposAccountTypesValue].includes(e.label as STARS_ACCOUNT_TYPE_ENUM)
        )
        break;
    }

    this.availableAccountTypes.sort(
      (a, b) => this.accountTypesOrder.indexOf(a.value) - this.accountTypesOrder.indexOf(b.value)
    );
    if (this.tenant && this.tenant == this.regenceId) this.availableAccountTypes = this.availableAccountTypes.filter(x => x.label != "Home Health Billing Office" && x.label != "Home Health Admin");
  }

  @Input() set accountId(value: number) {
    this.accountIdValue = value;
    this.buildAccountSwitchForm();
  }

  get accountId(): number {
    return this.accountIdValue;
  }

  @Input() set accountTypes(value: SelectItem[]) {
    this.accountTypesValue = value;
  }

  get accountTypes(): SelectItem[] {
    return this.accountTypesValue;
  }

  @Output() accountTypeIdNew = new EventEmitter<number>();

  tenant!: string;
  accountType!: SelectItem;
  showDialog = false;
  type!: SelectItem;
  accountSwitchForm!: FormGroup;
  newAccountId!: number;
  availableAccountTypes!: SelectItem[];

  constructor(private messageService: MessageService, private tenantsService: TenantsService) {}

  buildAccountSwitchForm(): void {
    this.accountSwitchForm = new FormGroup({
      accountTypeControl: new FormControl(this.accountId)
    });
    this.setFormWatch();
  }
  
  setFormWatch(): void {
    this.accountSwitchForm.valueChanges.subscribe(val => {
      const accountType = val.accountTypeControl;
      const isChangedToFromDualAccountTypesId =
        (!DualAccountTypesId.includes(this.accountIdValue) && DualAccountTypesId.includes(accountType)
        || DualAccountTypesId.includes(this.accountIdValue) && !DualAccountTypesId.includes(accountType));
      const isChangedToFromPacImAccountTypesId =
        (!PacImAccountTypesId.includes(this.accountIdValue) && PacImAccountTypesId.includes(accountType))
        || (PacImAccountTypesId.includes(this.accountIdValue) && !PacImAccountTypesId.includes(accountType));
        const isChangedToFromDmeposTypesId =
        (!DmeposAccountTypesId.includes(this.accountIdValue) && DmeposAccountTypesId.includes(accountType))
        || (DmeposAccountTypesId.includes(this.accountIdValue) && !DmeposAccountTypesId.includes(accountType));
      
      if (this.hasSelectedProviders && (isChangedToFromDualAccountTypesId || isChangedToFromPacImAccountTypesId || isChangedToFromDmeposTypesId)) {
        this.displayWarningMessage();
      } else {
        this.showDialogWindow(accountType);
      }     
    });
  }

  showDialogWindow(val: number): void {
    if (this.accountId === val) {
      return;
    }
    if (this.tenant && val) {
      this.showDialog = true;
      this.newAccountId = val;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        key: 'warn',
        detail: 'Please choose a Health Plan',
        life: 5000
      });
      this.accountSwitchForm
        .get('accountTypeControl')
        ?.setValue(this.accountId, { emitEvent: false });
    }
  }

  proceedUpdate(): void {
    this.showDialog = false;
    const newAccountType = {
      accountTypeId: this.newAccountId,
      tenantId: this.tenant
    };
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.newAccountId, { emitEvent: false });
    this.tenantsService.updateAccountType(this.userId, newAccountType).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        key: 'switched',
        detail: 'Account type changed',
        life: 5000
      });
      this.accountTypeIdNew.emit(this.newAccountId);
    });
  }

  cancelUpdate(): void {
    this.showDialog = false;
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.accountId, { emitEvent: false });
  }

  private displayWarningMessage(): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning',
      key: 'warn',
      detail: 'Please remove the selected providers before changing the Account Type',
      life: 5000
    });
    this.accountSwitchForm
      .get('accountTypeControl')
      ?.setValue(this.accountId, { emitEvent: false });
  }

  private displayWarningMessageForRegenceHPCM(): void {    
    this.messageService.add({
      severity: 'error',
      summary: 'Cannot Change Health Plan and Account Type',
      key: 'warn',
      detail: 'Regence Health Plan Case Managers must register using the Single Sign-On (SSO) process.',
      life: 5000
    });
    this.accountSwitchForm
    .get('accountTypeControl')
    ?.setValue(this.accountId, { emitEvent: false });
  }

}
