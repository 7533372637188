<div class="flex justify-content-between align-items-center py-2">
  <div class="flex align-items-center">
    <div class="col field">
      <label class="label">Search By:</label>
    </div>
    <div class="col">
      <p-dropdown
        placeholder="Select type"
        styleClass="default-field-size"
        [options]="searchTypes"
        [(ngModel)]="searchType"
        (onChange)="onChangeSearchType()"
        [disabled]="!tenant"
        appendTo="body"
      ></p-dropdown>
    </div>
    <div class="col" *ngIf="searchType === 'npi'">
      <input type="text" class="default-field-size" pInputText [(ngModel)]="npi" />
    </div>
    <div class="col" *ngIf="searchType === 'company'">
      <p-progressSpinner *ngIf="loadingParentCompanies" styleClass="p-progress-spinner-sm"></p-progressSpinner>
      <p-dropdown
        *ngIf="!loadingParentCompanies"
        placeholder="Select parent company"
        class="default-field-size"
        [filter]="true"
        [options]="companies"
        [(ngModel)]="companyName"
        appendTo="body"
      ></p-dropdown>
    </div>
    <div class="col">
      <button
        pButton
        class="p-button-raised"
        type="button"
        label="Search"
        [disabled]="disableSearch()"
        (click)="onSearch(searchType)"
      ></button>
    </div>
  </div>

  <div>
    <button
      pButton
      type="button"
      (click)="clearAllFilters()"
      label="Clear All Filters"
      class="mr-2"
      [disabled]="!isLoadingUsers && disabledClearAllButton"
    ></button>
    <button
      pButton
      type="button"
      (click)="onRemove()"
      [disabled]="!userSelectedItems.length"
      label="Remove Selected"
    ></button>
  </div>
</div>

<div class="grid">
  <div class="col-6">
    <p-table
      #dtp
      aria-label="Provider table"
      [alwaysShowPaginator]="false"
      class="table-parameters table-simple-paginator"
      [value]="foundProviders"
      [columns]="columns"
      [(selection)]="providerSelectedItems"
      [paginator]="true"
      [sortOrder]="defaultSortOrderProviders"
      [loading]="isLoadingProviders"
      [defaultSortOrder]="defaultSortOrderProviders"
      [sortField]="defaultSortFieldProviders"
      [totalRecords]="foundProvidersTotalRecords"
      [rows]="amountOfRows"
      [lazy]="true"
      responsiveLayout="scroll"
      (onLazyLoad)="handleLazyLoadProviders($event)"
      (onPage)="onPageChangeProviders()"
      (onRowSelect)="rowSelectProviders()"
      (onRowUnselect)="rowUnselectProviders()"
      resetTablePageOnSort
    >

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="table-icon-width">
            <p-checkbox
              [binary]="true"
              [disabled]="foundProviders && foundProviders.length === 0"
              [(ngModel)]="selectAllStateProviders"
              (onChange)="onChangeSelectAllStateProviders($event)"
            >
            </p-checkbox>
          </th>
          <th
            *ngFor="let col of columns | slice:0:-1"
            [ngStyle]="{ display: col.display }"
            [pSortableColumn]="unsortedFields.includes(col.field) ? '' : col.field"
            [pSortableColumnDisabled]="unsortedFields.includes(col.field)"
          >
            {{ col.header }}
            <p-sortIcon
              *ngIf="!unsortedFields.includes(col.field) && col.header !==''"
              [field]="col.field"
              ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order"
              ariaLabelAsc="Activate to sort in ascending order"
            >
            </p-sortIcon>
          </th>
        </tr>
        <tr class="header-filter">
          <th class="table-icon-width"></th>
          <th>
            <p-multiSelect
              #providerListMs
              styleClass="p-column-filter"
              [options]="providerNameListProviders"
              (onChange)="filterTableProviders($event.value, 'providerName', 'in')"
              [maxSelectedLabels]="0"
              [(ngModel)]="providerNameFilter"
              defaultLabel="Filter"
              [disabled]="!searchTypeCurrent"
              appendTo="body"
            ></p-multiSelect>
          </th>
          <th>
            <p-dropdown
              placeholder="Filter"
              styleClass="p-column-filter"
              [options]="activeStateListProviders"
              [showClear]="true"
              (onChange)="filterTableProviders($event.value, 'isActive', 'eq')"
              [(ngModel)]="provideActiveFilter"
              [disabled]="!searchTypeCurrent"
              appendTo="body"
            ></p-dropdown>
          </th>
          <th>
            <p-multiSelect
              #providerAddressMs
              styleClass="p-column-filter"
              [options]="providerAddressListProviders"
              (onChange)="filterTableProviders($event.value, 'providerAddress', 'in')"
              [maxSelectedLabels]="0"
              [(ngModel)]="providerAddressListFilter"
              defaultLabel="Filter"
              [disabled]="!searchTypeCurrent"
              appendTo="body"
            ></p-multiSelect>
          </th>
          <th>
            <p-multiSelect
              #providerNpiMs
              styleClass="p-column-filter"
              [options]="npiListProviders"
              (onChange)="filterTableProviders($event.value, 'npi', 'in')"
              [maxSelectedLabels]="0"
              [(ngModel)]="provideNpiFilter"
              defaultLabel="Filter"
              [disabled]="!searchTypeCurrent"
              appendTo="body"
            ></p-multiSelect>
          </th>
          <th>
            <p-multiSelect
              #providerTaxIdMs
              styleClass="p-column-filter"
              [options]="taxIdListProviders"
              (onChange)="filterTableProviders($event.value, 'taxId', 'in')"
              [maxSelectedLabels]="0"
              [(ngModel)]="provideTaxIdFilter"
              defaultLabel="Filter"
              [disabled]="!searchTypeCurrent"
              appendTo="body"
            ></p-multiSelect>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="6">
            No record found
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
          <td class="table-icon-width">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
          <td>{{ item.providerName }}</td>
          <td>{{ item.isActive ? 'Active' : 'Inactive' }}</td>
          <td>
            <span [pTooltip]="item.providerAddress"
              tooltipPosition="bottom">
              {{ item.providerAddress }}
            </span>
          </td>
          <td>{{ item.npi }}</td>
          <td>{{ item.taxId }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-6">
    <div class="grid">
      <div class="col-1 text-center pt-6">
        <div class="flex justify-content-center">
          <button
            type="button"
            class="p-ripple p-button p-component p-button-icon-only"
            (click)="shouldDisplayWarningMessageForHomeHealth()"
            [disabled]="disableAssignButton()"
          >
            <span class="p-button-icon pi pi-angle-right" aria-hidden="true"></span>
          </button>
        </div>

    </div>
    <div class="col-11">
      <p-table
        #dtu
        aria-label="Provider users table"
        [alwaysShowPaginator]="false"
        class="table-parameters table-simple-paginator"
        [value]="userProviders"
        [columns]="columns"
        [(selection)]="userSelectedItems"
        [paginator]="true"
        [sortOrder]="defaultSortOrder"
        [sortField]="defaultSortField"
        [loading]="isLoadingUsers"
        [defaultSortOrder]="defaultSortOrder"
        [totalRecords]="userProvidersTotalRecords"
        [rows]="amountOfRows"
        [lazy]="true"
        responsiveLayout="scroll"
        (onLazyLoad)="handleLazyLoadUserProviders($event)"
        (onPage)="onPageChangeUserProviders()"
        (onRowSelect)="rowSelectUsers()"
        (onRowUnselect)="rowUnselectUsers()"
        resetTablePageOnSort
      >
        <!-- <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 4rem" />
            <col *ngFor="let col of columns" [style.width]="col.width" />
          </colgroup>
        </ng-template> -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="table-icon-width">
              <p-checkbox
                [binary]="true"
                [disabled]="userProviders && userProviders.length === 0"
                [(ngModel)]="selectAllStateUserProviders"
                (onChange)="onChangeSelectAllStateUserProviders($event)"
              >
              </p-checkbox>
            </th>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="unsortedFields.includes(col.field) ? '' : col.field"
              [pSortableColumnDisabled]="unsortedFields.includes(col.field)"
              >
              <!-- <div class="table-header-sort"> -->
                {{ col.header }}
                <p-sortIcon
                  *ngIf="!unsortedFields.includes(col.field) && col.header !==''"
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                >
                </p-sortIcon>
              <!-- </div> -->
            </th>
          </tr>
          <tr class="header-filter">
            <th class="table-icon-width"></th>
            <th>
              <p-multiSelect
                #userProvidersMs
                styleClass="table-filter"
                [options]="providerNameList"
                (onChange)="filterTableUserProviders($event.value, 'providerName', 'in')"
                [maxSelectedLabels]="0"
                [(ngModel)]="userProviderNameFilter"
                defaultLabel="Filter"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-multiSelect>
            </th>
            <th>
              <p-dropdown
                #userStateListMs
                placeholder="Filter"
                styleClass="table-filter"
                [options]="activeStateList"
                [showClear]="true"
                (onChange)="filterTableUserProviders($event.value, 'isActive', 'eq')"
                [(ngModel)]="userActiveFilter"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-dropdown>
            </th>
            <th>
              <p-multiSelect
                #userAddressMs
                styleClass="table-filter"
                [options]="providerAddressList"
                (onChange)="filterTableUserProviders($event.value, 'providerAddress', 'in')"
                [maxSelectedLabels]="0"
                [(ngModel)]="userProviderAddressListFilter"
                defaultLabel="Filter"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-multiSelect>
            </th>
            <th>
              <p-multiSelect
                #userNpiMs
                styleClass="table-filter"
                [options]="npiList"
                (onChange)="filterTableUserProviders($event.value, 'npi', 'in')"
                [maxSelectedLabels]="0"
                [(ngModel)]="userNpiFilter"
                defaultLabel="Filter"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-multiSelect>
            </th>
            <th>
              <p-multiSelect
                #userTaxIdMs
                styleClass="table-filter"
                [options]="taxIdList"
                (onChange)="filterTableUserProviders($event.value, 'taxId', 'in')"
                [maxSelectedLabels]="0"
                [(ngModel)]="userTaxIdFilter"
                defaultLabel="Filter"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-multiSelect>
            </th>
            <th>
              <p-multiSelect
                #accountMs
                styleClass="table-filter"
                [options]="healthPlanList"
                (onChange)="filterTableUserProviders($event.value, 'tenantName', 'in')"
                [maxSelectedLabels]="0"
                [(ngModel)]="userTenantNameListFilter"
                defaultLabel="Filter"
                [showHeader]="false"
                [disabled]="disableUserProviderFilters()"
                appendTo="body"
              ></p-multiSelect>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="7">
              <p class="p-my-2">No record found</p>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr [pSelectableRow]="item">
            <td class="table-icon-width">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td>{{ item.providerName }}</td>
            <td>{{ item.isActive ? 'Active' : 'Inactive' }}</td>
            <td>
              <span [pTooltip]="item?.providerAddress"
                tooltipPosition="bottom">
                {{ item.providerAddress }}
              </span>
            </td>
            <td>{{ item.npi }}</td>
            <td>{{ item.taxId }}</td>
            <td>{{ item.tenantName }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
</div>
<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  appendTo="body"
  key="confirmRemoved"
></p-confirmDialog>
<p-confirmDialog
  header="Confirmation"
  appendTo="body"
  key="parentCompanyValidationForHH"
  [style]="{width:'28vw'}"
></p-confirmDialog>
<p-toast key="removed" position="bottom-left"></p-toast>
<p-toast key="error" position="bottom-left"></p-toast>
<p-toast key="success" position="bottom-left"></p-toast>
